import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus'; // const storeInfo = ref()

export default {
  __name: 'modal-newly',

  setup(__props, {
    expose: __expose
  }) {
    const titletext = ref();
    const centerDialogVisible = ref(false);

    const showModal = data => {
      console.log(data); //   storeInfo.value = data
      //   console.log(storeInfo.value)

      titletext.value = data.titletext;
      centerDialogVisible.value = true;
    };

    __expose({
      showModal
    });

    const rules = {
      rule: [{
        required: true,
        message: '请填写上级分组',
        trigger: 'blur'
      }],
      type: [{
        required: true,
        message: '请填写组别名称',
        trigger: 'blur'
      }],
      title: [{
        required: true,
        message: '请填写权限',
        trigger: 'blur'
      }],
      status: [{
        required: true,
        message: '请填写状态',
        trigger: 'blur'
      }]
    };
    const formRef = ref();
    const numberValidateForm = reactive({
      age: '',
      name: '',
      nickname: '',
      role: '',
      emiall: '',
      phone: '',
      status: '1'
    }); // 提交

    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          console.log('提交');
          centerDialogVisible.value = false;
          ElMessage({
            message: `${titletext.value}成功`,
            type: 'success'
          });
          formEl.resetFields();
        } else {
          console.log('填写有误!');
        }
      });
    }; // 重置


    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    }; // 关闭


    const close = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: centerDialogVisible.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => centerDialogVisible.value = $event),
        title: titletext.value,
        "align-center": "",
        onClose: _cache[6] || (_cache[6] = $event => close(formRef.value))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          rules: rules,
          model: numberValidateForm,
          "label-width": "auto",
          class: "demo-ruleForm"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "上级分组",
            prop: "rule"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.rule,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => numberValidateForm.rule = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "组别名称",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => numberValidateForm.type = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "权限",
            prop: "title"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.title,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => numberValidateForm.title = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[3] || (_cache[3] = $event => submitForm(formRef.value))
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }), _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = $event => resetForm(formRef.value))
            }, {
              default: _withCtx(() => [_createTextVNode("重置")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};